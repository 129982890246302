import React from 'react'

// import conponents
import Projects from '../components/Projects'

const Portfolio = () => {
    return (
        <section id='portfolio' className='section bg-primary min-h-[1400px]'>
            <div className=' container mx-auto'>
                <div className=' flex flex-col items-center text-3xl text-center'
                    data-aos='fade-up'
                    data-aos-offset='300'
                >
                    <h2
                        data-aos='fade-down'
                        data-aos-offset='100'
                        data-aos-duration='1000'
                        className=' selection:title before:content-portfolio relative before:absolute before:opacity-40
                    before:-top-[2] before:-left-3/4 before:hidden before:lg:block'> My latest work</h2>
                    <p
                        data-aos='fade-up'
                        data-aos-offset='300'
                        data-aos-duration='2000'
                        className='subtitle text-amber-300'> Creating a masterpiece is simple.</p>
                </div>
                <Projects />
            </div>
        </section>
    )
}

export default Portfolio;