//  icons
import {
  FiYoutube,
  FiInstagram,
  FiGithub,
  FiDribbble,
  FiLayout,
  FiSettings,
  FiPenTool,
  FiTag,
  FiMail,
  FiMapPin,
  FiAlertCircle
} from 'react-icons/fi';
import { FaReact, FaWordpressSimple, FaCode } from "react-icons/fa";


// companies icons
import FreelancerBrandIcon from './assets/img/brands/freelancer.png';
import UpworkBrandIcon from './assets/img/brands/upwork.png';
import FiverBrandIcon from './assets/img/brands/fiverr.png';
import BehanceBrandIcon from './assets/img/brands/behance.png';
import DribbbleBrandIcon from './assets/img/brands/dribbble.png';

// projects images
import Project1 from './assets/img/projects/foto1.png';
import Project2 from './assets/img/projects/foto2.png';
import Project3 from './assets/img/projects/foto3.png';
import Project4 from './assets/img/projects/foto4.png';
import Project5 from './assets/img/projects/foto5.png';
import Project6 from './assets/img/projects/foto6.png';
import Project7 from './assets/img/projects/foto7.png';
import Project8 from './assets/img/projects/foto8.png';
import Project9 from './assets/img/projects/foto9.png';

// skills images
import SkillImg1 from './assets/img/skills/html5.png';
import SkillImg2 from './assets/img/skills/css3.png';
import SkillImg3 from './assets/img/skills/js.png';
import SkillImg4 from './assets/img/skills/reactjs.png';
import SkillImg5 from './assets/img/skills/nextjs.png';
import SkillImg6 from './assets/img/skills/nodejs.png';
import SkillImg7 from './assets/img/skills/git.png';
import SkillImg8 from './assets/img/skills/figma.png';

// testimonial images
import TestiImage1 from './assets/img/testimonial/test1.png';
import TestiImage2 from './assets/img/testimonial/test1.jpg';
import TestiImage3 from './assets/img/testimonial/testimonial-3.webp';

// navigation
export const navigation = [
  {
    name: 'home',
    href: 'home',
  },
  {
    name: 'about',
    href: 'about',
  },
  {
    name: 'portfolio',
    href: 'portfolio',
  },
  {
    name: 'services',
    href: 'services',
  },
  {
    name: 'testimonials',
    href: 'testimonials',
  },
  {
    name: 'contact',
    href: 'contact',
  },
];

// social
export const social = [
  {
    icon: <FiYoutube />,
    href: '',
  },
  {
    icon: <FiInstagram />,
    href: 'https://www.instagram.com/vova_kopaihorodskyi/',
  },
  {
    icon: <FiGithub />,
    href: 'https://github.com/Vova-kf',
  },
  {
    icon: <FiDribbble />,
    href: '',
  },
];

// companies
export const brands = [
  {
    img: FreelancerBrandIcon,
    href: '',
  },
  {
    img: UpworkBrandIcon,
    href: '',
  },
  {
    img: FiverBrandIcon,
    href: '',
  },
  {
    img: BehanceBrandIcon,
    href: '',
  },
  {
    img: DribbbleBrandIcon,
    href: '',
  },
];

// projects
export const projectsData = [
  {
    id: '1',
    image: Project1,
    name: 'HTML/CSS/JS/G-sap/Responsive design',
    category: 'HTML/CSS/JS',
    href: 'https://vova-kf.github.io/portfolio/'
  },
  {
    id: '7',
    image: Project6,
    name: 'REACT.JS/Tailwind CSS/Responsive design',
    category: 'REACT.JS',
    href: 'https://furniture-w7d.pages.dev/'
  },
  {
    id: '3',
    image: Project8,
    name: 'HTML/Bootstrap/Animation/Responsive design',
    category: 'WORDPREES',
    href: 'https://www.g2techsoft.com/'
  },
  {
    id: '4',
    image: Project7,
    name: 'HTML/CSS/WORDPREES/Responsive design',
    category: 'WORDPREES',
    href: 'https://theplanetd.com/travel-blog/',
  },

  {
    id: '5',
    image: Project3,
    name: 'Gulp/HTML/CSS/JS/Responsive design',
    category: 'HTML/CSS/JS',
    href: 'https://vova-kf.github.io/Medical/',
  },
  {
    id: '6',
    image: Project5,
    name: 'REACT.JS/Tailwind CSS/Responsive design',
    category: 'REACT.JS',
    href: 'https://realestate-4uv.pages.dev/'
  },

  {
    id: '2',
    image: Project2,
    name: 'REACT.JSX/CSS/Animation/Responsive design',
    category: 'REACT.JS',
    href: 'https://portfolio-27a.pages.dev/',
  },



  {
    id: '8',
    image: Project9,
    name: 'HTML/CSS/WORDPREES/Responsive design',
    category: 'WORDPREES',
    href: 'https://www.atlasandboots.com/'
  },
  {
    id: '9',
    image: Project4,
    name: 'Gulp/HTML/CSS/JS/Responsive design',
    category: 'HTML/CSS/JS',
    href: 'https://vova-kf.github.io/mozii/'
  },

];

// projects
export const projectsNav = [
  {
    name: 'all',
  },
  {
    name: 'HTML/CSS/JS',
  },
  {
    name: 'WORDPREES',
  },
  {
    name: 'REACT.JS',
  },
];

// skill
export const skills = [
  {
    image: SkillImg1,
  },
  {
    image: SkillImg2,
  },
  {
    image: SkillImg3,
  },
  {
    image: SkillImg4,
  },
  {
    image: SkillImg5,
  },
  {
    image: SkillImg6,
  },
  {
    image: SkillImg7,
  },
  {
    image: SkillImg8,
  },
];

// services
export const services = [
  {
    icon: <FaCode />,
    name: 'HTML/CSS/JS',
    description:
      'If you need to convert the design to Figma, Psd, or Adobe XD, I can convert the design to HTML/CSS/JavaScript. I write code both from scratch and with the use of auxiliary resources. If necessary, I can use Bootstrap Jquery. Also, if necessary, I can upload the design to WordPress. I code the designs with an extension for different devices, and if necessary, I use animation to give the site an attractive look.',
  },
  {
    icon: <FaWordpressSimple />,
    name: 'WORDPREES',
    description:
      'Creating a website on WordPress, I can both code the design using HTML/CSS/JavaScript, and then import it to WordPress, and I can work with basic WordPress themes and edit them for different needs. To use different plugins in my work, for example, I can use Elementor to create a website, work with the Woocomerce plugin to create a store. I can create fully plug-in sites on various topics. As a result, you get a high-quality product ready for full-fledged work.',
  },
  {
    icon: <FaReact />,
    name: 'REACT.JS',
    description:
      'If you have a design and want to turn it into a React app, I can help you with that. I create beautiful adaptive sites, using various animations, in my work I can use both simple CSS/SCSS styles and use Tailwind CSS in my work.',
  },

];

// testimonials
export const testimonials = [
  {
    authorImg: TestiImage2,
    authorText:
      '"Great HTML developer. He managed to create a top-class HTML webpage within a few days. 10/10 recommended."',
    authorPosition: 'Figma to HTML',
  },
  {
    authorImg: TestiImage2,
    authorText:
      '"It was very nice to work with Volodymyr again. He made all work fast and with a perfect quality. It is our third project, hope to work with you more."',
    authorPosition: 'Add Articles to WP Site',
  },
  {
    authorImg: TestiImage2,
    authorText:
      '"Volodymyr is a great Wordpress specialist! He understood all requirments clearly and made the work with a big attention to details. I definetly recommend Volodymyr for Web Development work! Thanks!"',
    authorPosition: 'Wordpress Blog - Add Articles with Images',
  },
  {
    authorImg: TestiImage2,
    authorText:
      '"It was a great experience working with Volodomyr :)"',
    authorPosition: 'Responsive HTML & CSS Website',
  },
  {
    authorImg: TestiImage2,
    authorText:
      '"Nice guy to work with. Did a good job in helping me out with this issue. Thanks a lot!"',
    authorPosition: 'Improve alignment and styles of two tables',
  },
  {
    authorImg: TestiImage2,
    authorText:
      '"Volodymyr converted our website design from Figma to HTML/CSS efficiently and produced high quality work."',
    authorPosition: 'Figma to HTML/CSS for Medical Landing Page',
  },
  {
    authorImg: TestiImage2,
    authorText:
      '"Great HTML developer. He managed to create a top-class HTML webpage within a few days. 10/10 recommended."',
    authorPosition: 'Figma to HTML',
  },
  {
    authorImg: TestiImage2,
    authorText:
      '"It was very nice to work with Volodymyr again. He made all work fast and with a perfect quality. It is our third project, hope to work with you more."',
    authorPosition: 'Add Articles to WP Site',
  },
  {
    authorImg: TestiImage2,
    authorText:
      '"Volodymyr is a great Wordpress specialist! He understood all requirments clearly and made the work with a big attention to details. I definetly recommend Volodymyr for Web Development work! Thanks!"',
    authorPosition: 'Wordpress Blog - Add Articles with Images',
  },
  {
    authorImg: TestiImage2,
    authorText:
      '"It was a great experience working with Volodomyr :)"',
    authorPosition: 'Responsive HTML & CSS Website',
  },
  {
    authorImg: TestiImage2,
    authorText:
      '"Nice guy to work with. Did a good job in helping me out with this issue. Thanks a lot!"',
    authorPosition: 'Improve alignment and styles of two tables',
  },
  {
    authorImg: TestiImage2,
    authorText:
      '"Volodymyr converted our website design from Figma to HTML/CSS efficiently and produced high quality work."',
    authorPosition: 'Figma to HTML/CSS for Medical Landing Page',
  },
];

// contact
export const contact = [
  {
    icon: <FiMail />,
    title: 'Have a question?',
    subtitle: 'I am here to help you.',
    description: 'Email me at vwilly1507@gmail.com',
  },
  {
    icon: <FiMapPin />,
    title: 'Current Location',
    subtitle: 'Ukraine, Vinnytsia',
    description: 'Serving clients worldwide',
  },
];
