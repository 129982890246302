import React from 'react'

//import services data 
import { services } from '../data'

const Services = () => {
    return (
        <section id='services' className='section bg-tertiary '>
            <div className=' container mx-auto'>
                <div className=' flex flex-col items-center'>
                    <h2
                        data-aos='fade-down'
                        data-aos-offset='300'
                        data-aos-duration='2000'
                        className=' section-title before:content-services 
                relative before:absolute before:opacity-40 
                before:-top-[2rem] before:-left-28 before:hidden before:lg:block'>
                        What I do for clients</h2>
                    <p className='subtitle text-amber-300 text-center'>
                        The quality of your product/website, its comfort in use depends on the choice of a specialist.
                    </p>
                </div>
                <div className='grid lg:grid-cols-3 gap-8'>
                    {services.map((service, index) => {
                        const { icon, name, description } = service;
                        return (
                            <div className=' bg-secondary p-6 rounded-2xl' key={index}>
                                {/* icon */}
                                <div
                                    data-aos='fade-down'
                                    data-aos-offset='100'
                                    data-aos-duration='1000'
                                    className=' text-amber-400 rounded-sm w-12 h-12 flex justify-center items-center mb-6 text-[28px]'>
                                    {icon}
                                </div>
                                <h4
                                    data-aos='fade-up'
                                    data-aos-offset='300'
                                    data-aos-duration='2000'
                                    className=' text-amber-200'>{name}</h4>
                                <p
                                    data-aos='fade-down'
                                    data-aos-offset='300'
                                    data-aos-duration='2000'
                                    className='mt-6'>{description}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section >
    )
}

export default Services;