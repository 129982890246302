import React from 'react'
import { Link } from 'react-scroll';



const Project = ({ item }) => {
    return (
        <div

            key={item.id} className='flex flex-col items-center text-center'>
            <div className='mb-8'>
                <img className=' rounded-2x1' src={item.image} alt='' />
            </div>
            <p className=' capitalize text-amber-300 text-sm mb-3'>{item.category}</p>

            <h3 className='text-2x1 font-semibold mb-3'>{item.name}</h3>
            <a className='btn btn-md bg-amber-400 hover:bg-amber-200 hover:text-black' href={item.href}> View now</a>

        </div>
    )
}

export default Project;